import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.trim.js";
import { login as _login, logout as _logout, getInfo as _getInfo } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
import { listForRouter } from '@/api/system/menu';
var state = {
  token: getToken(),
  name: '',
  avatar: '',
  permissions: null,
  roles: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_PROFILE: function SET_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var dispatch = _ref.dispatch,
      commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password,
      capCode = userInfo.capCode;
    return new Promise(function (resolve, reject) {
      _login({
        account: username.trim(),
        password: password,
        capCode: capCode
      }).then(function (data) {
        if (data.code == 200) {
          commit('SET_TOKEN', data.data.token);
          setToken(data.data.token);
          // 本地储存
          localStorage.setItem('userId', data.data.userId);
          resolve();
        } else {
          console.log(data.message);
          reject(data.message);
        }
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _getInfo().then(function (data) {
        if (!data.roles || data.roles.length <= 0) {
          reject('请联系管理员，该用户没有分配角色');
        }
        var name = data.name,
          profile = data.profile,
          permissions = data.permissions,
          roles = data.roles;
        commit('SET_NAME', name);
        commit('SET_AVATAR', profile.avatar);
        commit('SET_PROFILE', profile);
        commit('SET_ROLES', roles);
        commit('SET_PERMISSIONS', permissions);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        commit('SET_TOKEN', '');
        commit('SET_NAME', '');
        commit('SET_AVATAR', '');
        commit('SET_PROFILE', {});
        commit('SET_ROLES', []);
        commit('SET_PERMISSIONS', []);
        removeToken();
        // 删除本地储存
        localStorage.removeItem('userId');
        resetRouter();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  },
  updateToken: function updateToken(_ref5, _ref6) {
    var commit = _ref5.commit;
    var token = _ref6.token;
    console.log('newToken', token);
    commit('SET_TOKEN', token);
    setToken(token);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};