//
//
//
//
//
//
//
//
//
//
//
//
//

import store from "@/store";
export default {
  computed: {
    language: function language() {
      return this.$store.state.app.language;
    }
  },
  methods: {
    handleSetLanguage: function handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('app/setLanguage', lang);
      var accessRoutes = this.$store.dispatch('menu/getSideMenus');
      this.$message({
        message: 'Switch Language Success',
        type: 'success'
      });
    }
  }
};