import "/root/workspace/OKEY-front_sY8I/node_modules/_core-js@3.39.0@core-js/modules/es.array.iterator.js";
import "/root/workspace/OKEY-front_sY8I/node_modules/_core-js@3.39.0@core-js/modules/es.promise.js";
import "/root/workspace/OKEY-front_sY8I/node_modules/_core-js@3.39.0@core-js/modules/es.object.assign.js";
import "/root/workspace/OKEY-front_sY8I/node_modules/_core-js@3.39.0@core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ECharts from 'vue-echarts/components/ECharts.vue';
import '@/styles/index.scss'; // global css
import httpRequest from '@/utils/request';
import App from './App';
import store from './store';
import router from './router';
import _i18n from './lang'; // Internationalization
import '@/icons'; // icon
import '@/permission'; // permission control
import './assets/css/formbottom.css';
import 'babel-polyfill';
import $ from 'jquery';
import axios from 'axios';
Vue.prototype.$axios = axios;
import moment from 'moment'; //导入文件
Vue.prototype.$moment = moment; //赋值使用

import { openCommonFile } from "@/utils/openFile";
Vue.prototype.$openCommonFile = openCommonFile;
Vue.use(ElementUI, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';
Vue.use(preview);
Vue.prototype.$http = httpRequest;
Vue.component('v-chart', ECharts);
Vue.config.productionTip = false;
import echarts from 'echarts';
Vue.prototype.$echarts = echarts;

//树形结构列表
import TreeTable from 'vue-table-with-tree-grid';

//组织架构图(vue-org-tree)
import Vue2OrgTree from 'vue2-org-tree';
// import 'vue2-org-tree/dist/style.css'

Vue.use(Vue2OrgTree);
Vue.component('tree-table', TreeTable);
Vue.use(preview);
import Base64 from '@/utils/base64.js';
Vue.prototype.$Base64 = Base64;

//视频播放
import VideoPlayer from 'vue-video-player';
Vue.use(VideoPlayer);
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
Vue.use(VueQuillEditor);
//地图
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: 'YOUR_GOOGLE_MAPS_API_KEY_GOES_HERE',
    libraries: 'places'
  }
});
new Vue({
  el: '#app',
  router: router,
  store: store,
  VueQuillEditor: VueQuillEditor,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});