//
//
//
//
//
//
//
//
//
//

import { isExternal } from '@/utils/validate';
export default {
  name: 'AppMain',
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.fullPath;
    }
  },
  methods: {
    isFrame: function isFrame() {
      if (isExternal(this.$route.fullPath)) {
        return true;
      } else {
        return false;
      }
    }
  }
};