import request from '@/utils/request';
var baseURL = process.env.VUE_APP_BASE_API_R;
export function login(data) {
  return request({
    url: baseURL + '/account/login',
    method: 'post',
    data: data
  });
}
export function getInfo() {
  return request({
    url: baseURL + '/user/accontInfo',
    method: 'get'
  });
}
export function logout(token) {
  return request({
    url: baseURL + '/account/logout',
    method: 'post'
  });
}
export function updatePwd(params) {
  return request({
    url: baseURL + '/account/updatePwd',
    method: 'post',
    data: params
  });
}