export default {
  route: {
    dashboard: 'Dashboard',
    home: 'home',
    system: 'System'
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    profile: 'Profile',
    updatePwd: 'Modify Password',
    github: 'Github',
    screenfull: 'Screenfull',
    theme: 'Theme',
    size: 'Global Size'
  },
  login: {
    title: 'OKEY',
    errorAccount: 'Please enter the correct user name',
    errorPassword: 'The password can not be less than 5 digits',
    logIn: 'Log in',
    username: 'Username',
    password: 'Password',
    capCode: 'CAPTCHA'
  },
  button: {
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    search: 'Search',
    reset: 'Reset',
    submit: 'Submit',
    cancel: 'Cancel',
    clear: 'Clear',
    back: 'Back',
    export: 'Export',
    save: 'Save',
    approve: 'Approve'
  },
  common: {
    mustSelectOne: 'Please select the record to operate!',
    deleteConfirm: 'Are you sure delete the record?',
    tooltip: 'Tooltip',
    warning: 'Warning',
    optionSuccess: 'success',
    uploadError: 'Upload Error',
    isRequired: ' is required',
    week: {
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun'
    }
  },
  店铺名称: "Shop Name",
  店铺LOGO: "Shop Logo",
  活动标题: "Activity Title",
  店铺: "Shop",
  活动内容: "Activity Remark",
  操作: "Option",
  查看: "Details",
  修改: "Modify",
  删除: "Delete",
  设备: "Equipment",
  预约消息: "Appointment Message",
  预约人: "Appointment Person",
  预约状态: "Appointment State",
  待确认: "TO CONFIRM",
  已确认: "CONFIRMED",
  已取消: "CANCEL",
  店铺地址: "Shop Address",
  营业开始时间: "Opening Hours",
  营业结束时间: "Closing Hours",
  联系电话: "Mobile",
  是否营业中: "is Opening",
  管理员: "Admin",
  营业执照: "Business License",
  商铺详情: "Shop Details",
  查看详情: "Show Detail"
};