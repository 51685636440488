var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      staticClass: "international",
      attrs: { trigger: "click" },
      on: { command: _vm.handleSetLanguage },
    },
    [
      _c(
        "div",
        [
          _c("svg-icon", {
            attrs: {
              "class-name": "international-icon",
              "icon-class": "language",
            },
          }),
        ],
        1
      ),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.language === "zh", command: "zh" } },
            [_vm._v("中文")]
          ),
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.language === "en", command: "en" } },
            [_vm._v("English")]
          ),
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.language === "tr", command: "tr" } },
            [_vm._v("Türkçe")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }