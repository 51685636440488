//
//
//
//
//
//

export default {
  name: 'App'
  // components: {
  //   GoogleMap
  // }
};