import "core-js/modules/es.array.map.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export function setSideMenus(Menus) {
  /* 存储的是Json 字符串*/
  localStorage.setItem('sideMenus', JSON.stringfy(Menus));
}
export function getSideMenus() {
  var strMenus = localStorage.getItem('sideMenus');
  return JSON.parse(strMenus);
}
export function traverseRoutes(menus) {
  var routes = menus.map(function (menu) {
    if (menu.component) {
      var name = menu.component;
      console.log("traverseRoutes name", name);
      menu.component = function () {
        return import("@/".concat(name));
      };
    }
    if (menu.children && menu.children.length) {
      menu.children = traverseRoutes(menu.children);
    }
    return menu;
  });
  return routes;
}