import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import { listForRouter } from '@/api/system/menu';
import { traverseRoutes } from '@/utils/route';
import { constantRoutes } from '@/router';
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  getSideMenus: function getSideMenus(_ref) {
    var commit = _ref.commit,
      state = _ref.state;
    return new Promise(function (resolve, reject) {
      listForRouter().then(function (data) {
        var menus = data;
        var remoteroutes = traverseRoutes(menus);
        commit('SET_ROUTES', remoteroutes);
        resolve(remoteroutes);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};