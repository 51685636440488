var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar navmyself" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          [
            _c(
              "el-select",
              {
                staticClass: "selectClass",
                attrs: { size: "small", filterable: "", placeholder: "请选择" },
                model: {
                  value: _vm.valueUtc,
                  callback: function ($$v) {
                    _vm.valueUtc = $$v
                  },
                  expression: "valueUtc",
                },
              },
              _vm._l(_vm.optionsUtc, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.clickOptionsUtc(item)
                    },
                  },
                })
              }),
              1
            ),
          ],
          [_c("lang-select", { staticClass: "international right-menu-item" })],
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("div", { staticClass: "v-user" }, [
                  _vm._v(_vm._s(_vm.user.name)),
                ]),
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: require("@/assets/img/avatar.gif") },
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/account/profile" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(" " + _vm._s(_vm.$t("navbar.profile")) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/account/updatePwd" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(" " + _vm._s(_vm.$t("navbar.updatePwd")) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }