import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import router from '@/router';
import de from "element-ui/src/locale/lang/de";

// create an axios instance
var service = axios.create({
  baseURL: "",
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000 // request timeout
});
// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  var token = getToken();
  if (token) {
    config.headers['accessToken'] = "".concat(token); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  var timeUTC = new Date();
  //打印结果为：Wed Aug 31 2022 10:47:48 GMT+0800 (中国标准时间)
  console.log('aaaa', timeUTC);
  var GMT = (timeUTC + "").split(" ");
  var UTC = GMT[5];

  // console.log('aaaaaaaaaaaaa', store)
  config.headers['language'] = store.state.app.language;
  config.headers['plat'] = "MANAGE";
  config.headers['utc'] = localStorage.getItem("utc");
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  if (response.headers.token) {
    //如果后台通过header返回token，说明token已经更新，则更新客户端本地token
    store.dispatch('user/updateToken', {
      token: response.headers.token
    });
  }
  // console.log("dddddddddddddd")
  console.log(response);
  return response.data;
}, function (error) {
  console.log(error);
  console.log(error.response);
  if (error.response.status === 401) {
    store.dispatch('user/logout').then(function () {
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.path
        }
      });
    });
    return;
  }
  Message({
    message: error.response.data || error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;