export function openCommonFile(fileUrl) {
  // 使用 Microsoft Office Online 预览链接
  // 创建加载提示元素
  var loadingElement = document.createElement('div');
  loadingElement.textContent = '正在加载...';
  loadingElement.style.position = 'fixed';
  loadingElement.style.top = '50%';
  loadingElement.style.left = '50%';
  loadingElement.style.transform = 'translate(-50%, -50%)';
  loadingElement.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
  loadingElement.style.color = '#fff';
  loadingElement.style.padding = '10px';
  loadingElement.style.borderRadius = '5px';
  loadingElement.style.zIndex = '9999';

  // 使用 Microsoft Office Online 预览链接
  var officePreviewUrl = "https://view.officeapps.live.com/op/view.aspx?src=".concat(encodeURIComponent(fileUrl));

  // 打开新窗口并加载预览链接
  var newWindow = window.open('', '_blank');
  if (newWindow) {
    // 在新窗口加载时显示加载提示
    newWindow.document.write("<html><body>".concat(loadingElement.outerHTML, "</body></html>"));
    newWindow.document.close();

    // 加载预览链接
    newWindow.location.href = officePreviewUrl;

    // 在加载完成后移除加载提示
    newWindow.onload = function () {
      newWindow.document.body.removeChild(newWindow.document.querySelector('div'));
    };

    // 将焦点设置到新窗口
    newWindow.focus();
  } else {
    alert('无法打开预览窗口，请检查浏览器设置。');
  }
}