import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
var baseURL = process.env.VUE_APP_BASE_API_R;
export function getList(params) {
  return request({
    url: baseURL + '/menu/getMenus',
    method: 'get',
    params: params
  });
}
export function getMenus() {
  return request({
    url: baseURL + '/menu/getMenusConfig',
    method: 'get'
  });
}
export function getMenuTreeListByRoleId(params) {
  return request({
    url: baseURL + '/menu/getMenuTreeListByRoleId',
    method: 'get',
    params: params
  });
}
export function listForRouter(params) {
  return request({
    url: baseURL + '/menu/systemMenu',
    method: 'get',
    params: params
  });
}
export function savePermissons(data) {
  return request({
    url: baseURL + '/role/savePermissons',
    method: 'post',
    data: data
  });
}
export function save(data) {
  return request({
    url: baseURL + '/menu/save',
    method: 'post',
    data: data
  });
}
export function delMenu(id) {
  return request({
    url: baseURL + "/menu/remove?menuId=".concat(id),
    method: 'get'
  });
}
export function menuTreeListByRoleId(roleId) {
  return request({
    url: baseURL + "/menu/getMenuTreeListByRoleId?roleId=".concat(roleId),
    method: 'get'
  });
}
export function menuTreeListByRoleIdDomainId(roleId, domainId) {
  return request({
    url: baseURL + "/menu/getMenuTreeListByRoleIdDomainId?roleId=".concat(roleId, "&domainId=").concat(domainId),
    method: 'get'
  });
}