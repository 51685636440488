export default {
  route: {
    dashboard: '首页',
    home: '首页',
    system: '系统管理'
  },
  navbar: {
    logOut: '退出登录',
    profile: '个人资料',
    updatePwd: '修改密码',
    dashboard: '首页',
    github: '项目地址',
    screenfull: '全屏',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: 'OKEY',
    errorAccount: '请输入5到32位的数字和字母',
    errorPassword: '密码至少位5位',
    logIn: '登录',
    username: '账号',
    password: '密码',
    capCode: '验证码'
  },
  button: {
    add: '添加',
    addShop: '新建店铺',
    edit: '修改',
    delete: '删除',
    search: '查询',
    reset: '重置',
    submit: '提交',
    cancel: '取消',
    clear: '清除',
    back: '返回',
    export: '导出',
    save: '保存',
    approve: '审批'
  },
  common: {
    mustSelectOne: '请选中操作项!',
    deleteConfirm: '你确认删除该记录？',
    tooltip: '提示',
    warning: '警告',
    optionSuccess: '操作成功',
    uploadError: '上传文件失败',
    isRequired: '不能为空',
    week: {
      mon: '周一',
      tue: '周二',
      wed: '周三',
      thu: '周四',
      fri: '周五',
      sat: '周六',
      sun: '周日'
    }
  },
  shop: {
    shopName: "店铺名称",
    测试: "1111111111",
    活动标题: "活动标题",
    店铺: "店铺",
    活动内容: "活动内容",
    操作: "操作",
    查看: "查看",
    设备: "设备",
    预约消息: "预约消息",
    预约人: "预约人",
    预约状态: "预约状态",
    待确认: "待确认",
    已确认: "已确认",
    已取消: "已取消"
  },
  店铺名称: "店铺名称",
  店铺LOGO: "店铺Logo",
  店铺地址: "店铺地址",
  营业开始时间: "营业开始时间",
  营业结束时间: "营业结束时间",
  联系电话: "联系电话",
  是否营业中: "是否营业中",
  管理员: "管理员",
  营业执照: "营业执照",
  商铺详情: "商铺详情",
  操作: "操作",
  查看: "查看",
  修改: "修改",
  删除: "删除",
  查看详情: "查看详情",
  盘点单号: "盘点单号",
  盘点仓库: "盘点仓库"
};